<template>
  <div class="activity">
    <left-tabs :tabList="leftList" />
     <router-view></router-view>
  </div>
</template>
<script>
import ContentTitle from "@/components/contentTitle";
import LeftTabs from "@/components/leftTabs";
export default {
  name: "activity",
  components: {
    ContentTitle,
    LeftTabs,
  },
  data() {
    return {
        leftList: [
          // {id: 1, name: '优惠券', url: '/activity/liveLottery'},
          {id: 1, name: '秒杀活动', url: '/activity/couponlist'},
		      // {id: 3, name: '秒杀活动', url: '/activity/OhSnap'},
          {id: 2, name: '九宫格抽奖', url: '/activity/sudoku'},
          {id: 3, name: '九宫格中奖信息', url: '/activity/lotterywinList'}
        ],
    };
  },
  methods: {
    
  },

};
</script>
<style scoped lang="less">
.activity {
  display: flex;
}

</style>